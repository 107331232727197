import vhCheck from 'vh-check';
import browserUpdate from 'browser-update/update.npm';
import 'focus-visible/dist/focus-visible'; // Auto-executing, see : https://github.com/WICG/focus-visible

// See : https://github.com/browser-update/browser-update/wiki/Details-on-configuration
browserUpdate({
  required: {
    e: 16, // Edge
    i: 12, // IE
    f: -2, // Firefox
    s: -2, // Safari
    c: -2, // Chrome
  },
  insecure: true,
});

let test = null;
const vhUpdate = () => {
  test = vhCheck({
    cssVarName: 'vh-offset',
  });
  document.documentElement.style.setProperty('--vh-offset', `${test.offset}px`);
};

window.addEventListener('resize', vhUpdate);
document.addEventListener('DOMContentLoaded', vhUpdate);
